<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            7. Эвакуационные выходы
          </v-card-title>
        </v-col>
        <v-col cols="6">
          <v-card class="pa-2">
            <v-card-title >
              Количество и ширина выходов зависят от:
            </v-card-title>
              <v-card
                class="pa-2 d-flex justify-center align-center"
                full-width
                flat

                  >
                <v-img
                  lazy-src="img/material/businessman.svg"
                  max-width="80"
                  src="img/material/businessman.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  максимального числа эвакуирующихся людей
                </v-card-text>
              </v-card>
              <v-card
                class="pa-2 d-flex justify-center align-center"
                full-width
                flat

                  >
                <v-img
                  lazy-src="img/material/route.svg"
                  max-width="80"
                  src="img/material/route.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  предельно допустимого расстояния от наиболее удаленного рабочего места до ближайшего эвакуационного выхода
                </v-card-text>
              </v-card>
          </v-card>
        </v-col>
        <v-col cols=6>
          <v-dialog
            v-model="dialog1"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              block
              color="red"
              dark
              v-attr="attrs"
              v-on="on"
              class="mb-5"
              height="50"
              >
                Аварийные выходы
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                Аварийные выходы
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <span class="red--text font-weight-bold">К аварийным выходам относятся:</span>
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                выход на балкон или лоджию с глухим простенком не менее 1,2м от торца балкона (лоджии) до оконного проема (остекленной двери) или не менее 1,6 между остекленными проемами, выходящими на балкон (лоджию);
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                выход на открытый наружный переход (галерею) шириной не менее 0,6м, ведущей в смежную пожарную секцию или в смежный пожарный отсек;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                выход на балкон или лоджию, ширина которых составляет не менее 0,6м, оборудованные лестницей (в тем числе складной), поэтажно соединяющей балконы или лоджии с люком размером не менее 0,6х0,8м в полу балкона (лоджии) для доступа на нижележащий балкон (лоджию);
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                выход непосредственно наружу из помещений с отметкой чистого поля не ниже мину 4,5м и не выше плюс 5,0м через окно или дверь с размерами не менее 0,75х1,5м, а также через люк размерами не менее 0,6х0,8м;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                выход на кровлю зданий I, II и III степеней огнестойкости классов С0 и С1 через окно, дверь или люк с размерами и лестницей в соответствии с перечислением в предыдущем пункте.
              </v-card-text>

              <v-card-text class="title font-weight-bold">
                <p>Указанные выходы, а также мероприятия и средства, предназначенные для спасения людей, не учитываются при проектировании путей эвакуации из помещений и зданий.</p>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialog2"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              block
              color="red"
              dark
              v-attr="attrs"
              v-on="on"
              class="mb-5"
              height="50"
              >
                Эвакуационные выходы
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                Эвакуационные выходы
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <span class="red--text font-weight-bold">Выходы являются эвакуационными, если они ведут:</span>
              </v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="6">
                    <v-card-title>
                      1. Из помещений 1 и цокольного этажа наружу:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      непосредственно;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      через коридор;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      через вестибюль (фойе);
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      через лестничную клетку;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      через коридор и вестибюль (фойе);
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      через коридор, холл (рекреационную площадку) и лестничную клетку.
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      через вестибюль (фойе);
                    </v-card-text>
                  </v-col>
                  <v-col cols="6">
                    <v-card-title>
                      2. Из помещений любого этажа, кроме 1:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      непосредственно в лестничную клетку при условиях, оговоренных в нормативных документах по ПБ;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      непосредственно на лестницу 3 типа;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      в коридор, ведущий непосредственно в лестничную клетку или на лестницу 3 типа;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      в холл (фойе), имеющий выход непосредственно в лестничную клетку или на лестницу 3 типа;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      на эксплуатируемую кровлю или на специально оборудованный участок кровли, который ведет в лестничную клетку или на лестницу 3 типа, в том числе через коридор.
                    </v-card-text>

                  </v-col>
                  <v-col cols="10">
                    <v-card-text class="title font-weight-light">
                      <span class="title font-weight-bold">3. В соседнем помещении</span> кроме помещения класса Ф5 категории А или Б на том же этаже, обеспеченное выходами, указанными в пунктах 1 и 2.
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialog3"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              block
              color="red"
              dark
              v-attr="attrs"
              v-on="on"
              class="mb-5"
              height="50"
              >
                Не эвакуационные выходы
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                Не эвакуационные выходы
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <span class="red--text font-weight-bold">Выходы не являются эвакуационными, если в их проемах установлены:</span>
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                раздвижные двери и ворота;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                подъемно-опускные двери и ворота в коридорах и лестничных клетках;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                подъемно-опускные двери и ворота, за исключением случаев, когда обеспечена их жесткая фиксация в открытом состоянии с соблюдением выхода эвакуационного выхода;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                распашные ворота шириной более 3,5м, ворота для железнодорожного подвижного состава и автомобильного транспорта;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                вращающиеся двери и турникеты.
              </v-card-text>

              <v-card-text class="title font-weight-bold">
                <p>Распашные калитки в указанных воротах, за исключением подъемно-опускных, могут считаться выходами, если она обеспечивают необходимые геометрические параметры при любом положении ворот.</p>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog3 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialog4"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              block
              color="red"
              outlined
              v-attr="attrs"
              v-on="on"
              class="mb-5"
              height="50"
              >
                Предельно допустимое расстояние
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                Предельно допустимое расстояние
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="6">

                    <v-card-text class="title font-weight-light">
                      Предельно допустимое расстояние от наиболее удаленного рабочего места до ближайшего эвакуационного выхода, измеряется по оси эвакуационного пути.
                    </v-card-text>

                    <v-card-text class="title font-weight-bold">
                      Ограничивается в зависимости от:
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      класса функциональной пожарной опасности и категории взрывопожароопасности помещения и здания;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      численности эвакуируемых;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      геометрических параметров помещений и эвакуационных путей;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      класса конструктивной пожарной опасности;
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      степени огнестойкости здания.
                    </v-card-text>

                    <v-card-text class="title font-weight-light">
                      Предельно допустимое расстояние следует принимать по СП 1.13130.2020 в зависимости от назначения здания.
                    </v-card-text>
                  </v-col>
                  <v-col cols="6" class="d-flex justify-center">
                    <v-img
                      lazy-src="img/material/plan.jpg"
                      max-width="50%"
                      contain
                      src="img/material/plan.jpg"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog4 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
                    <v-dialog
            v-model="dialog5"
            width="80%"
            class="pa-16"

          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              block
              color="red"
              outlined
              v-attr="attrs"
              v-on="on"
              class="mb-5"
              height="55"

              >
                Допускается предусматривать эвакуационные выходы
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                Объекты организаций торговли
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <span class="red--text font-weight-bold">На объектах организаций торговли ЗАПРЕЩАЕТСЯ:</span>
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                осуществлять продажу ЛВЖ и ГЖ, ГГ (в том числе баллонов с газом, лакокрасочных изделий, растворителей, товаров в аэрозольной упаковке), пороха, капсюлей, пиротехнических и других взрывоопасных изделий, если здание не является зданием класса функциональной пожарной опасности Ф 3.1;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <p>Торговые залы отделяются противопожарными преградами от кладовых с емкостями ГЖ.</p>
                <p>Емкости (резервуары, бочки) должны быть не более 5м³.</p>
                <p>Тара из-под ЛВЖ и ГЖ хранится на специальных огражденных площадках.</p>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog5 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12">
          <v-card-title >
            Не менее 2 эвакуационных выходов должны иметь:
          </v-card-title>
        </v-col>
        <v-col cols="6">
          <v-dialog
            v-model="dialog7"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/hotel-2.svg"
                  max-width="80"
                  src="img/material/hotel-2.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title text-center font-weight-light">
                  Помещения
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                Помещения
              </v-card-title>
              <!-- <v-card-text class="title font-weight-bold">
                Помещения
              </v-card-text> -->

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                как правило подвальных и цокольных этажей (заглубленный более чем на 0,5м), предназначенные для одновременного пребывания более 15 человек;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                в помещениях подвальных и цокольных этажей, предназначенный для одновременного пребывания от 6 по 15 человек, один из двух выходов допускается предусматривать в соответствии с требованиями 6.20г;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                предназначенные для одновременного пребывания более 50 человек;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                помещения, за исключением помещений класса Ф5, рассчитанные на единовременное пребывание в нем 50 человек (в том числе амфитеатр или балкон зрительного зала), с расстоянием вдоль прохода от наиболее удаленного места (рабочего места) до эвакуационного выхода более 25м;
              </v-card-text>
              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                помещение, если суммарное количество людей, находящихся в нем и примыкающих помещениях (с эвакуационным выходом только через это помещение), составляет 50 и более человек.
              </v-card-text>

              <v-card-text class="title font-weight-bold">
                Если из помещения требуется устройство не менее 2 эвакуационных выходов, то через одно соседнее помещение допускается предусматривать не более 50% таких выходов.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog7 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
            v-model="dialog8"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/stairs.svg"
                  max-width="80"
                  src="img/material/stairs.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title text-center font-weight-light">
                  Этажи зданий
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
               <v-card-title class="headline mb-5" dark>
                Этажи зданий
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                класса Ф1.1: Детские дошкольные учреждения, специализированные дома престарелых и инвалидов (неквартирные), больницы, спальные корпуса школ-интернатов и детских учреждений);
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                класса Ф1.2: Гостиницы, общежития, спальные корпуса санаториев и домов отдыха общего типа, кемпингов, мотелей и пансионатов;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                класса Ф2.1: Театры, кинотеатры, концертные зала, клубы, цирки спортивные сооружения с трибунами, библиотеки и другие учреждения с расчетным числом посадочных мест для посетителей в закрытых помещениях;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                класса Ф2.2: Музеи, выставки, танцевальные залы и другие подобные учреждения в закрытых помещениях;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                класса Ф3: Предприятия по обслуживанию населения;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                класса Ф3: Предприятия по обслуживанию населения;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                класса Ф4: Учебные заведения, научные и проектные организации, учреждения управления;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                с численностью 50 и более человек на этаже;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                подвальные и цокольные этажи, заглубленные более чем на 0,5м, при площади более 300м² или предназначенные для одновременного пребывания более 15 человек.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog8 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <Hint></Hint>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null,
    dialog3: null,
    dialog4: null,
    dialog5: null,
    dialog6: null,
    dialog7: null,
    dialog8: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>

</style>
